import styled from "styled-components";

export const SkillContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 40px; 
    align-items: center;
    padding: 55px 0px 55px 0px;

    > div {
        display: flex;
        flex-wrap: wrap;  
        gap: 10px 20px; 
        justify-content: center; 
    }

    .bodyBold {
        color: ${props => props.theme.color.secondary};
        text-align: center; 
    }

`

export const SkillItems = styled.button`
    border: 2px solid ${props => props.theme.color.secondary};
    background-color: ${props => props.theme.color.transperant};
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    color: ${props => props.theme.color.secondary};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.color.tertiary};
    }

    &.clicked {
        background-color: ${props => props.theme.color.tertiary}; 
        border: none;
        color: ${props => props.theme.color.white};
    }

    transition: all 0.2s ease-in-out;

`

export const LanguagesContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;

    > svg {

        cursor: pointer;
        path {
            fill: ${props => props.theme.color.white};
        }

        &:hover, &.selected {
            path {
                fill: ${props => props.theme.color.secondary}
            }
        }

    }
    
`



export const ProjectItem = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: center;
    gap: 80px;
    align-items: center;
    transition: all 1s ease-in-out;
    padding: 55px 0px 55px 0px;
    max-height: 1000px;
    overflow: hidden;

    &.hidden {
        padding: 0; 
        max-height: 0px;
    }

    @media(max-width: 1000px) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
    }

    > div:first-child {
        display: flex; 
        justify-content: flex-start; 
        max-width: 80%;
    }

    > div:last-child {
        display: flex;
        flex-direction: column; 
        gap: 20px; 
        color: ${props => props.theme.color.white};
        min-width: 50%;
        max-width: 50%;
         
        @media(max-width: 1000px) {
            max-width: 75%; 
        }

        @media(max-width: 700px) {
            max-width: 90%; 
        }
    

        > div {
            display: flex; 
            gap: 20px; 

            @media(max-width: 1000px) {
                justify-content: center;
            }
        
            .title2 {
                color: ${props => props.theme.color.secondary}
            }
        }
    }
`

export const ProjectImg = styled.img`
    max-width: 100%;
`