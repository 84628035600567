import styled from "styled-components";
import { SectionContainer } from "../../styles/Containers";
import { Link } from "react-scroll";


export const HeroContainer = styled(SectionContainer)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
// This will be the nav bar at the top of screen on initial load
// It will contain icons that relate to contact information and git hub portfolio
export const TempNavBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex; 
    justify-content: flex-end;    
    padding: 5% 5% 0 0%;
    max-width: 1440px;
    margin: 0 auto;

    > div {
        display: flex; 
        gap:20px;
    }
`


export const TitleContainer = styled.div`
    > h3 {
        color: ${props => props.theme.color.secondary}
    } 
`

export const NavBarContainer = styled.div`
    padding-top: 30px;
    background-color: ${props => props.theme.color.background};
    display: flex; 
    justify-content: center;
    gap: 20px;

    > div {
        display: flex; 
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        > div:last-child {
            display: flex;
            justify-content: space-between;
            width: 300px;
        }
    }

    &.sticky {
        padding: 15px 0 15px 0;
        max-width: 100vw;
        position: fixed; 
        top: 0; 
        left: 0;
        right: 0;
        z-index: 1000;

        > div {
            max-width: 1440px;
            padding: 0 5% 0 5%;
        }
    }
`

export const NavBarItem = styled(Link)`
    @keyframes hop {
        0%, 70% {
        transform: translateY(0);
        }

        20% {
        transform: translateY(-10px); /* Adjust the value to control the hop height */
        }

        50% {
            transform: translateY(2px); /* Adjust the value to control the hop height */
        }
    }


    line-height: ${props => props.theme.fonts.title1.lineHeight}; 
    size: ${props => props.theme.fonts.title1.size};
    font-weight: ${props => props.theme.fonts.title1.fontWeight};
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.color.secondary};
    }

    &.hopping {
        animation: hop 1.5s ease-in-out infinite;
    }

    &.visible {
        color: ${props => props.theme.color.secondaryDark};
    }
`