import { useRef, useState, useContext } from 'react';
import { ProjectContainer, ProjectAndSkillsSection } from './styles';
import { SectionVisibilityContext } from '../../context/SectionVisibilityContext';
import Projects from './Components/Projects';
import { useInView } from 'react-intersection-observer'
import TruckerToolImg from '../../assets/projects/TruckerTool/trucker_tool.png'
import CityOfVancouverImg from '../../assets/projects/CityOfVancouver/city_of_vancouver.png'
import BetterFiImg from '../../assets/projects/Betterfi/betterfi.png'
import TruckingAppImg from '../../assets/projects/TruckingApp/trucking-app.png'
import ThreeDChess from '../../assets/projects/3dChess/3d-chess.png'
import PlanerImg from '../../assets/projects/Planer/planer.png'
import JavaImageUploadServerImg from '../../assets/projects/JavaImageUploadServer/java-image-server.png';
import CSharpMulticastNetwork from '../../assets/projects/CSharpMulticastNetwork/c-sharp-multicast-network.png'

export enum LANGUAGES {
    PYTHON = "PYTHON",
    CSHARP = "C#",
    JAVA = "JAVA",
    JS = "JS",
    TS = "TS",
    // PHP = "PHP",
    SQL = "SQL",
};

export enum PROJECT_IDS {
    JAVA_IMG_UPLOAD = '11',
    CSHARP_NETWORK = '22',
    TRUCKING_APP = '33',
    TRUCKING_TOOL = '44',
    BETTER_FI = '55',
    COV_TEOD = '66',
    PLANER = '77',
    CHESS = '88',
}

export type ProjectData = {
    img: string,
    name: string,
    description: string,
    live?: string,
    git?: string,
    more?: string,
    categories: string[],
    languages: LANGUAGES[],
    id: PROJECT_IDS,
}

const SECTION_ID = 'projects'

const ProjectAndSkills = () => {

    const categories: string[] = ['Full Stack', 'Frontend', 'Backend', 'Express', 'GIT', 'Docker', 'Github CI/CD', 'NodeJS', 'React', 'React Native', '.Net', 'Test Driven Development', 'Games', 'MVC', 'Android', 'Multi Threading', 'REST', 'Sockets', 'Multicast']

    const [selectedCategories, setCategories] = useState<string[]>([]);
    const [selectedLanguages, setLanguages] = useState<LANGUAGES[]>([]);
    const { setVisibleSection } = useContext(SectionVisibilityContext);

    const { ref } = useInView({
        threshold: 0.15,
        onChange: (inView) => {
            if (inView) setVisibleSection("projects");
        }
    })
    const toggleCategories = (cat: string) => {
        if (selectedCategories.includes(cat)) {
            setCategories(selectedCategories.filter(c => c != cat)) // Remove categroy
        } else {
            setCategories([...selectedCategories, cat]);
        }
    }

    const toggleLanguages = (lang: LANGUAGES) => {
        if (selectedLanguages.includes(lang)) {
            setLanguages(selectedLanguages.filter(l => l != lang))
        } else {
            setLanguages([...selectedLanguages, lang]);
        }
    }

    const pData: ProjectData[] = [
        {
            img: TruckingAppImg,
            name: 'Tare Ticketing / Trucking App',
            description: 'Trucking App is a comprehensive solution addressing the invoicing challenges in the aggregate logistic industry, offering a transparent and efficient platform. By unifying stakeholders and streamlining processes, it enhances payment efficiency and eliminates industry bottlenecks.',
            categories: ['Full Stack', 'Frontend', 'Backend', 'GIT', 'Docker', 'Github CI/CD', 'React Native', 'Flask', 'Test Driven Development', 'React', 'REST'],
            languages: [LANGUAGES.TS, LANGUAGES.PYTHON, LANGUAGES.SQL],
            id: PROJECT_IDS.TRUCKING_APP,
        },
        {
            img: TruckerToolImg,
            name: "Trucker Tool",
            description: "Trucker Tool is an innovative application developed during the QDSHacks 2023 hackathon, focusing on the theme of big data. Our primary objective was to create a user-friendly application and extract data that provides essential fuel consumption statistics for trucks",
            live: 'https://qds-2023.web.app/',
            git: 'https://github.com/Rshokar/trucking-app',
            categories: ["Frontend", 'GIT', 'React'],
            languages: [LANGUAGES.JS, LANGUAGES.PYTHON],
            id: PROJECT_IDS.TRUCKING_TOOL
        },
        {
            img: BetterFiImg,
            name: "Better Fi",
            description: "BetterFi prepares developers for job interviews. The application provides users with practice questions, courses, and AI-generated cover letters.",
            more: 'https://devpost.com/software/betterfi',
            git: 'https://github.com/Rshokar/betterfi',
            categories: ["Frontend", 'GIT', 'React', 'NodeJS', 'Full Stack', 'Backend', 'Express', 'REST'],
            languages: [LANGUAGES.JS],
            id: PROJECT_IDS.BETTER_FI,
        },
        {
            img: CityOfVancouverImg,
            name: "COV TEOD Conduit Asset Managment",
            description: "In a team of four members we worked with the City of Vancouver to create SPARC Conduit Asset Management for the traffic electrical on operation department. We crafted a full-stack application to manage duct and conduit assets. ",
            live: 'https://client-88301.web.app/',
            more: 'https://citystudiovancouver.com/projects/sparc-conduit-asset-management/',
            categories: ["Frontend", 'GIT', 'React', 'Full Stack', 'Backend', '.Net', 'Test Driven Development', 'REST'],
            languages: [LANGUAGES.CSHARP, LANGUAGES.SQL, LANGUAGES.TS],
            id: PROJECT_IDS.COV_TEOD
        },
        {
            img: PlanerImg,
            name: 'Planer',
            description: 'When planning a vacation, consolidating visa, currency, and COVID restriction information is a monstrous task. Here at Planer, we plan on bringing the correct information for travelers, making it easier to choose your next big vacation.',
            git: 'https://github.com/Rshokar/Planer',
            categories: ['Full Stack', 'Frontend', 'Backend', 'GIT', 'Android', 'MVC',],
            languages: [LANGUAGES.JAVA],
            id: PROJECT_IDS.PLANER,

        },
        {
            img: ThreeDChess,
            name: '3D Chess',
            description: "This chess project was developed as part of BCIT's Object-Oriented course (2510), aiming to enhance our understanding of object-oriented languages and design patterns. The project primarily focuses on key concepts such as inheritance, polymorphism, and abstraction.",
            git: 'https://github.com/Rshokar/Chess-and-3D-Chess',
            categories: ['Games', 'Frontend'],
            languages: [LANGUAGES.JAVA],
            id: PROJECT_IDS.CHESS
        },
        {
            img: JavaImageUploadServerImg,
            name: 'Java Image Upload Server',
            description: 'A multi-threaded Java server and command line Java client that communicate via a socket.The server is designed to handle image uploads, providing a simple and efficient way to transfer images over the network.',
            git: 'https://github.com/Rshokar/Java-Image-Upload-Server',
            categories: ['Backend', 'Multi Threading', 'REST', 'Sockets'],
            languages: [LANGUAGES.JAVA],
            id: PROJECT_IDS.JAVA_IMG_UPLOAD,
        },
        {
            img: CSharpMulticastNetwork,
            name: 'C# Multi Cast Network',
            description: 'n the world of online gaming, networking often goes unnoticed despite its crucial importance. To enhance our understanding of what happens behind the scenes, I have developed a multi-threaded Multicast Network that enables communication between two separate processes over a network.',
            git: 'https://github.com/Rshokar/MultiCast-Network',
            categories: ['Backend', 'Multi Threading', 'Sockets', 'Multicast'],
            languages: [LANGUAGES.CSHARP],
            id: PROJECT_IDS.CSHARP_NETWORK,
        }
    ]

    return (
        <ProjectAndSkillsSection id={SECTION_ID} >

            <ProjectContainer ref={ref}>
                <h2>
                    PROJECTS
                </h2>
                <Projects
                    projects={pData}
                    toggleCategories={toggleCategories}
                    toggleLanguages={toggleLanguages}
                    selectedCategories={selectedCategories}
                    selectedLanguages={selectedLanguages}
                    categories={categories}
                />
            </ProjectContainer>
        </ProjectAndSkillsSection>
    )
}

export default ProjectAndSkills