import { createContext, useState, useContext } from "react";


interface SectionVisibilityContextType {
    visibleSection: string;
    setVisibleSection: React.Dispatch<React.SetStateAction<string>>;
}

export const SectionVisibilityContext = createContext<SectionVisibilityContextType>({
    visibleSection: 'HERO',
    setVisibleSection: () => { }
});

export const useSectionVisibility = () => useContext(SectionVisibilityContext);

interface SectionVisibilityProviderProps {
    children: React.ReactNode
}

export const SectionVisibilityProvider = ({ children }: SectionVisibilityProviderProps) => {
    const [visibleSection, setVisibleSection] = useState<string>('');

    const value = { visibleSection, setVisibleSection };
    return (
        <SectionVisibilityContext.Provider value={value}>
            {children}
        </SectionVisibilityContext.Provider>
    )
}