import * as React from "react"
import { SVGProps } from "react"
const PythonSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 20 20"
        {...props}
    >
        <title>{"python [#127]"}</title>
        <path
            fill="#000"
            fillRule="evenodd"
            d="M12.744 18.458a.874.874 0 0 1-.872-.877.873.873 0 1 1 1.744 0c0 .485-.39.877-.872.877M10.072 20c5.078 0 4.761-2.214 4.761-2.214l-.006-2.292H9.982v-.689h6.769S20 15.175 20 10.026c0-5.15-2.835-4.968-2.835-4.968h-1.693v2.39s.091 2.85-2.79 2.85H7.876s-2.7-.043-2.7 2.624v4.41S4.766 20 10.072 20M7.257 1.542c.482 0 .871.392.871.877a.872.872 0 1 1-1.743 0c0-.485.39-.877.872-.877M9.928 0c-5.077 0-4.76 2.214-4.76 2.214l.006 2.292h4.845v.689h-6.77S0 4.825 0 9.974c0 5.15 2.836 4.968 2.836 4.968h1.692v-2.39s-.091-2.85 2.791-2.85h4.805s2.7.043 2.7-2.624v-4.41S15.234 0 9.928 0"
        />
    </svg>
)
export default PythonSVG
