import { DefaultTheme } from "styled-components";


export const theme: DefaultTheme = {
    color: {
        primary: "#023047",
        secondary: "#FB8500",
        secondaryDark: "#BB3669",
        secondaryLight: "#FACA20",
        tertiary: "#57836D",
        background: "#A5D2E7",
        black: "#131313",
        white: "#FFFFFF",
        transperant: "rgba(255, 255, 255, 0)"
    },
    fonts: {
        h1: {
            lineHeight: "1.4",
            size: "48pt",
            fontWeight: "bold"
        },
        h2: {
            lineHeight: "1.4",
            size: "40pt",
            fontWeight: "bold"
        },
        h3: {
            lineHeight: "1.4",
            size: "33pt",
            fontWeight: "bold"
        },
        h4: {
            lineHeight: "1.4",
            size: "28pt",
            fontWeight: "bold"
        },
        h5: {
            lineHeight: "1.4",
            size: "23pt",
            fontWeight: "bold"
        },
        title1: {
            lineHeight: "1.4",
            size: "19pt",
            fontWeight: "bold"
        },
        title2: {
            lineHeight: "1.4",
            size: "16pt",
            fontWeight: "bold"
        },
        body: {
            lineHeight: "1.4",
            size: "14pt",
            fontWeight: "regular"
        },
        bodyBold: {
            lineHeight: "1.4",
            size: "13pt",
            fontWeight: "bold"
        },
        caption: {
            lineHeight: "1.4",
            size: "11pt",
            fontWeight: "bold"
        }
    },
}