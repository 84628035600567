import styled from "styled-components";
import { SectionContainer } from "../../styles/Containers";
import HeadShot from '../../assets/images/headshot.png';


export const AboutContainer = styled(SectionContainer)`
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    gap: 100px; 

    @media (max-width: 1200px) {
        flex-direction: column;
      }
`

export const ProfileImage = styled.div`
    min-height: 350px; 
    min-width: 350px;
    border-radius: 50%;
    border: 5px solid ${props => props.theme.color.secondary};

    background-image: url(${HeadShot});
    background-size: cover;
    background-position: center;
`


export const Text = styled.div<{ $showText?: boolean }>`
    color: ${props => props.theme.color.white};

    > p {
        transition: max-height 0.5s ease-in-out;
        max-height: ${props => props.$showText ? '3000px' : '100px'};
        overflow: hidden;
        white-space: pre-wrap;
    }
`