import React, { useState } from 'react'
import { CSSProp, css, useTheme } from 'styled-components'
import { ProjectItem, ProjectImg, SkillContainer, SkillItems, LanguagesContainer } from './styles'
import { SecondaryIconButton } from '../../../../styles/Buttons'
import WebSiteSVG from '../../../../assets/svgs/website'
import GitHubSVG from '../../../../assets/svgs/github'
import OpenInNewWindowSVG from '../../../../assets/svgs/newWindow'
import { LANGUAGES, ProjectData } from '../../ProjectAndSkillsSection'
import CSharpSVG from '../../../../assets/svgs/languages/CSharp'
import JavaSVG from '../../../../assets/svgs/languages/Java'
import JavaScriptSVG from '../../../../assets/svgs/languages/JavaScript'
import PHPSVG from '../../../../assets/svgs/languages/PHP'
import PythonSVG from '../../../../assets/svgs/languages/Python'
import SQLSVG from '../../../../assets/svgs/languages/SQL'
import TypeScriptSVG from '../../../../assets/svgs/languages/TypeScript'

interface SkillsProps {
    categories: string[],
    toggleCategories: (cat: string) => void;
    selectedCats: string[]
}

export const Skills = ({ categories, toggleCategories, selectedCats }: SkillsProps) => {
    return <SkillContainer>
        <div>
            {categories.map((c, i) => <SkillItems
                className={`caption ${selectedCats.some(cat => cat === c) ? 'clicked' : ''}`}
                onClick={() => toggleCategories(c)}
                key={i}>
                {c}
            </SkillItems>
            )}
        </div>
        <span className='bodyBold'>
            Click a skill or language to filter projects
        </span>
    </SkillContainer>

}



export interface ProjectProps {
    project: ProjectData,
    hidden: boolean,
}

const Project = ({ project, hidden }: ProjectProps) => {
    const theme = useTheme()
    return <ProjectItem data-testid={project.id} className={hidden ? 'hidden' : ''}>
        <div>
            <ProjectImg src={project.img} />
        </div>
        <div>
            <h5>{project.name}</h5>
            <span className='body'>
                {project.description}
            </span>
            <div>
                {
                    project.live &&
                    <SecondaryIconButton onClick={() => window.open(project.live, '_blank')}>
                        <WebSiteSVG />
                    </SecondaryIconButton>
                }
                {
                    project.git &&
                    <SecondaryIconButton onClick={() => window.open(project.git, '_blank')}>
                        <GitHubSVG fill={theme.color.secondary} />
                    </SecondaryIconButton>
                }
                {
                    project.more &&
                    <SecondaryIconButton onClick={() => window.open(project.more, '_blank')}>
                        <OpenInNewWindowSVG />
                    </SecondaryIconButton>
                }

                {
                    !project.live && !project.git && !project.more &&
                    <span className='title2'>
                        UNDER CONSTRUCTION
                    </span>
                }
            </div>
        </div>
    </ProjectItem>
}

interface LanguageProps {
    toggleLanguage: (lang: LANGUAGES) => void;
    langs: LANGUAGES[];
}

export const Languages = ({ toggleLanguage, langs }: LanguageProps) => {
    return <LanguagesContainer>
        <CSharpSVG data-testid={LANGUAGES.CSHARP} className={langs.some(l => l == LANGUAGES.CSHARP) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.CSHARP)} />
        <JavaSVG data-testid={LANGUAGES.JAVA} className={langs.some(l => l == LANGUAGES.JAVA) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.JAVA)} />
        <JavaScriptSVG data-testid={LANGUAGES.JS} className={langs.some(l => l == LANGUAGES.JS) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.JS)} />
        <TypeScriptSVG data-testid={LANGUAGES.TS} className={langs.some(l => l == LANGUAGES.TS) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.TS)} />
        {/* <PHPSVG data-testid={LANGUAGES.PHP} className={langs.some(l => l == LANGUAGES.PHP) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.PHP)} /> */}
        <PythonSVG data-testid={LANGUAGES.PYTHON} className={langs.some(l => l == LANGUAGES.PYTHON) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.PYTHON)} />
        <SQLSVG data-testid={LANGUAGES.SQL} className={langs.some(l => l == LANGUAGES.SQL) ? 'selected' : ''} onClick={() => toggleLanguage(LANGUAGES.SQL)} />
    </LanguagesContainer>
}

interface ProjectsProps {
    projects: ProjectData[];
    toggleCategories: (cat: string) => void;
    toggleLanguages: (lang: LANGUAGES) => void;
    selectedCategories: string[];
    selectedLanguages: LANGUAGES[];
    categories: string[];
}

const Projects = ({ projects, toggleCategories, toggleLanguages, selectedLanguages, selectedCategories, categories }: ProjectsProps) => {
    return (<>
        <Languages toggleLanguage={toggleLanguages} langs={selectedLanguages} />
        <Skills categories={categories} toggleCategories={toggleCategories} selectedCats={selectedCategories} />
        {projects.map((p, index) => {
            let cats = selectedCategories.every(cat => p.categories.includes(cat));
            let languages = selectedLanguages.every(lang => p.languages.includes(lang));
            return <Project key={index} project={p} hidden={!(cats && languages)} />
        })}
    </>)
}

export default Projects