import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme'
import GlobalStyles from './styles/GlobalStyle'
import { SectionWrapper } from './styles/Containers';
import { Hero, About, ProjectAndSkillsSection } from './Sections'
import ContentBackground from './assets/images/site_background.png'
import { Contact } from './Sections/Contact';
import { SectionVisibilityProvider } from './context/SectionVisibilityContext';

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SectionVisibilityProvider>
          <GlobalStyles />
          <SectionWrapper>
            < Hero />
          </SectionWrapper>
          <SectionWrapper image={ContentBackground}>
            <About />
            <ProjectAndSkillsSection />
            <Contact />
          </SectionWrapper>
        </SectionVisibilityProvider>
      </ThemeProvider>

    </div>
  );
}


export default App;
