import React, { useState, useContext } from 'react'
import { SectionVisibilityContext } from '../../context/SectionVisibilityContext';
import { AboutContainer, ProfileImage, Text } from './styles';
import { PrimaryButton, SecondaryButton } from '../../styles/Buttons';
import { useInView } from 'react-intersection-observer';

const SECTION_ID = 'about';

const About: React.FC = () => {
    const [showText, setShowText] = useState<boolean>(false);
    const { setVisibleSection } = useContext(SectionVisibilityContext);

    const shortText = "Hello! I'm excited to introduce myself as a full-stack candidate, eagerly stepping into this new chapter after years of hard work and dedication."
    const longText = `
Hello! I'm excited to introduce myself as a full-stack candidate, eagerly stepping into this new chapter after years of hard work and dedication.

My journey began as CEO of AKS Trucking Ltd, where I led the company to exceed $1 million in annual revenue. Despite this success, I realized my true passion lay elsewhere, leading me to transition from aggregate logistics to the world of computers and software. This shift was fueled by a childhood fascination with technology, sparked by games like Age of Empires 2 and Halo CE.

Seeking to transform my passion into expertise, I enrolled in BCIT's CST program. There, I gained a deep understanding of computer system theory and hands-on software development experience, particularly through direct learning from industry professionals. A notable project was collaborating with the City of Vancouver on a conduit asset management application, where I applied cutting-edge software engineering techniques.

Currently, I'm with Sandman Media, managing the development and maintenance of 80 Joomla PHP sites, working closely with designers and clients to meet their needs. This role involves everything from automating releases to customizing UI according to client specifications.

Beyond work and academics, I'm an outdoor enthusiast who enjoys hiking, camping, and traveling. As a player in the ASHL Adult Safe Hockey League, I embrace the spirit of teamwork and stay active.

Thank you for taking a look at my site. I'm enthusiastic about the opportunity to contribute to your organization and eagerly await your response.`

    const { ref } = useInView({
        onChange: (inView) => {
            if (inView) {
                setVisibleSection('about');
            }
        },
        threshold: .25,
    })

    return <AboutContainer id={SECTION_ID} ref={ref}>

        <ProfileImage />
        <Text $showText={showText}>
            <span className='title2'>
                Hello! I'm excited to introduce myself as a full-stack candidate, eagerly stepping into this new chapter after years of hard work and dedication.</span>
            <p className='body'>
                {longText}
            </p>
            {showText ?
                <SecondaryButton onClick={() => setShowText(false)}>
                    <span className='title2'>LESS</span>
                </SecondaryButton>
                :
                <PrimaryButton onClick={() => setShowText(true)}>
                    <span className='title2'>MORE</span>
                </PrimaryButton>
            }
        </Text>

    </AboutContainer>
}


export default About;