import React, { FC } from 'react';
import styled, { css, CSSProp } from 'styled-components';


const Button = styled.button`
    padding: 10px 35px 10px 35px;
    border: none; 
`

export const PrimaryButton = styled(Button)`
    background-color: ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.white};
`


export const SecondaryButton = styled(Button)`
    background-color: ${props => props.theme.color.transperant};
    border: 2px solid ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.secondary}; 
`

const IconButton = styled.button<{ styles: CSSProp }>`
    width: 50px;
    height: 50px; 
    border: none;
    border-radius: 50%; 
    cursor: pointer;
    ${props => props.styles}
    display: flex;
    justify-content: center;
    align-items: center; 
`;

export const PrimaryIconButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const primaryStyles: CSSProp = css`
        background-color: ${props => props.theme.color.secondary}; 
        transition: all 0.2s ease-in-out;

        &:hover{
            background-color: ${props => props.theme.color.secondaryDark}
        }

        > svg {
            fill: ${props => props.theme.color.white}
        }
    `

    return (
        <IconButton {...props} styles={primaryStyles}>
            {props.children}
        </IconButton>
    );
};

export const SecondaryIconButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const primaryStyles: CSSProp = css`
        background-color: ${props => props.theme.color.transperant};
        border: 2px solid ${props => props.theme.color.secondary}; 
        transition: all 0.2s ease-in-out;

        &:hover{
            background-color: ${props => props.theme.color.secondaryDark}
        }

        > svg {
            stroke: ${props => props.theme.color.secondary};
        }

    `

    return (
        <IconButton {...props} styles={primaryStyles}>
            {props.children}
        </IconButton>
    );
};
