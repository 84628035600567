import React from 'react'
import { ContactMeContainer } from './styles'
import { PrimaryIconButton } from '../../styles/Buttons'
import LinkedInSVG from '../../assets/svgs/linkedin'
import GitHubSVG from '../../assets/svgs/github'
import EmailSVG from '../../assets/svgs/email'

const emailAddress = "ravindershokar@gmail.com"

const ContactMe = () => {

    const handleLinkedInClick = () => window.open("https://www.linkedin.com/in/rshokar/", "_blank")
    const handleGitHubClick = () => window.open("https://github.com/Rshokar", "_blank")
    const handleEmailClick = () => window.open(`mailto:${emailAddress}`);

    return (
        <ContactMeContainer>
            <PrimaryIconButton onClick={handleLinkedInClick}>
                <LinkedInSVG />
            </PrimaryIconButton>
            <PrimaryIconButton onClick={handleGitHubClick}>
                <GitHubSVG />
            </PrimaryIconButton>
            <PrimaryIconButton onClick={handleEmailClick}>
                <EmailSVG />
            </PrimaryIconButton>
        </ContactMeContainer>
    )
}

export default ContactMe