import * as React from "react"
import { SVGProps } from "react"
const OpenInNewWindowSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        viewBox="3 3 18 18"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 12V6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-6m-3.889-7H12m0 0v3.889M12 12l-7 7"
        />
    </svg>
)
export default OpenInNewWindowSVG
