import styled from "styled-components";
import { SectionContainer } from "../../styles/Containers";


export const ContactSection = styled(SectionContainer)`

    display: flex; 
    flex-direction: column; 
    gap: 20px;
    max-width: 600px;
    align-items: center;  
    justify-content: center;
    height: 80vh;

    > h2, .body {
        color: ${props => props.theme.color.white};
        text-align: center;
    }

    > div {
        display: flex; 
        gap: 20px;
        justfiy-content: center;
    } 
`