import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Inter';
        src: url('/fonts/Inter-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url('/fonts/Inter-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    * {
        box-sizing: border-box;
    }

    body {
        background-color: ${props => props.theme.color.background};
        font-family: 'Inter', sans-serif; /* Set the default font for the body */
    }

    h1, h2, h3, h4, h5 {
        margin: 0;
    }

    h1 {
        line-height: ${props => props.theme.fonts.h1.lineHeight};
        font-size: ${props => props.theme.fonts.h1.size};
        font-weight: ${props => props.theme.fonts.h1.fontWeight};
    }

    h2 {
        line-height: ${props => props.theme.fonts.h2.lineHeight};
        font-size: ${props => props.theme.fonts.h2.size};
        font-weight: ${props => props.theme.fonts.h2.fontWeight};
    }

    h3 {
        line-height: ${props => props.theme.fonts.h3.lineHeight};
        font-size: ${props => props.theme.fonts.h3.size};
        font-weight: ${props => props.theme.fonts.h3.fontWeight};
    }

    h4 {
        line-height: ${props => props.theme.fonts.h4.lineHeight};
        font-size: ${props => props.theme.fonts.h4.size};
        font-weight: ${props => props.theme.fonts.h4.fontWeight};
    }

    h5 {
        line-height: ${props => props.theme.fonts.h5.lineHeight};
        font-size: ${props => props.theme.fonts.h5.size};
        font-weight: ${props => props.theme.fonts.h5.fontWeight};
    }

    .title1 {
        line-height: ${props => props.theme.fonts.title1.lineHeight};
        font-size: ${props => props.theme.fonts.title1.size};
        font-weight: ${props => props.theme.fonts.title1.fontWeight};
    }

    .title2 {
        line-height: ${props => props.theme.fonts.title2.lineHeight};
        font-size: ${props => props.theme.fonts.title2.size};
        font-weight: ${props => props.theme.fonts.title2.fontWeight};
    }

    .body {
        line-height: ${props => props.theme.fonts.body.lineHeight};
        font-size: ${props => props.theme.fonts.body.size};
        font-weight: ${props => props.theme.fonts.body.fontWeight};
    }

    .bodyBold {
        line-height: ${props => props.theme.fonts.bodyBold.lineHeight};
        font-size: ${props => props.theme.fonts.bodyBold.size};
        font-weight: ${props => props.theme.fonts.bodyBold.fontWeight};
    }

    .caption {
        line-height: ${props => props.theme.fonts.caption.lineHeight};
        font-size: ${props => props.theme.fonts.caption.size};
        font-weight: ${props => props.theme.fonts.caption.fontWeight};
    }

    @media(max-width: 900px) {
        h1 {
            line-height: ${props => props.theme.fonts.h3.lineHeight};
            font-size: ${props => props.theme.fonts.h3.size};
            font-weight: ${props => props.theme.fonts.h3.fontWeight};
        }
    
        h2 {
            line-height: ${props => props.theme.fonts.h4.lineHeight};
            font-size: ${props => props.theme.fonts.h4.size};
            font-weight: ${props => props.theme.fonts.h4.fontWeight};
        }
    
        h3 {
            line-height: ${props => props.theme.fonts.h5.lineHeight};
            font-size: ${props => props.theme.fonts.h5.size};
            font-weight: ${props => props.theme.fonts.h5.fontWeight};
        }

    
    }
`;

export default GlobalStyle;
