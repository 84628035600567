import styled from "styled-components";
import { SectionContainer } from "../../styles/Containers";


export const ProjectAndSkillsSection = styled(SectionContainer)`
    display: flex; 
    flex-direction: column; 
    align-items: center;
    gap: 100px; 

    h2 {
        text-align: center; 
        color: ${props => props.theme.color.white};
        padding-bottom: 55px;
    }
`

export const ProjectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
`