import * as React from "react"
import { SVGProps } from "react"
const JavaScriptSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        fill="none"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            fill="#000"
            d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v2a.75.75 0 0 1-1.5 0v-2a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h13.5a.75.75 0 0 1 0 1.5H1.75A1.75 1.75 0 0 1 0 14.25V1.75Zm8.25 4A.75.75 0 0 1 9 6.5v4c0 1.005-.276 1.758-.845 2.232-.543.453-1.201.518-1.655.518a.75.75 0 0 1 0-1.5c.346 0 .563-.06.695-.17.106-.088.305-.335.305-1.08v-4a.75.75 0 0 1 .75-.75Zm3.026.83c.418-.498 1.075-.83 1.974-.83.835 0 1.465.286 1.89.735a.75.75 0 0 1-1.09 1.03c-.11-.116-.33-.265-.8-.265-.5 0-.72.168-.826.295A.767.767 0 0 0 12.25 8c0 .104.043.3.174.455.107.127.325.295.826.295.076 0 .149.011.218.032.663.094 1.164.39 1.506.798.394.47.526 1.024.526 1.42 0 .396-.132.95-.526 1.42-.418.498-1.075.83-1.974.83-.835 0-1.465-.286-1.89-.735a.75.75 0 0 1 1.09-1.03c.11.116.33.265.8.265.5 0 .72-.168.826-.295A.767.767 0 0 0 14 11c0-.104-.043-.3-.174-.455-.107-.127-.325-.295-.826-.295a.748.748 0 0 1-.218-.032c-.663-.095-1.164-.39-1.506-.798A2.263 2.263 0 0 1 10.75 8c0-.396.132-.95.526-1.42Z"
        />
    </svg>
)
export default JavaScriptSVG
