import * as React from "react"
import { SVGProps } from "react"
const JavaSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 32 32"
        {...props}
    >
        <path d="M17.625 3c1.402 3.309-5.027 5.336-5.625 8.094-.547 2.531 3.809 5.5 3.813 5.5-.665-1.047-1.149-1.93-1.813-3.563C12.875 10.273 20.855 7.785 17.625 3Zm4.25 4.594s-5.621.355-5.906 4.031c-.13 1.637 1.484 2.496 1.531 3.688.04.972-.969 1.78-.969 1.78s1.809-.327 2.375-1.812c.625-1.648-1.218-2.773-1.031-4.094.18-1.261 4-3.593 4-3.593Zm1.375 8.468c-.59-.027-1.254.192-1.844.625 1.164-.257 2.157.473 2.157 1.313 0 1.883-2.688 3.656-2.688 3.656s4.156-.465 4.156-3.562c0-1.278-.8-1.985-1.781-2.032Zm-11.031.032c-1.45.05-4.344.289-4.344 1.406 0 1.555 6.742 1.676 11.563.719 0 0 1.312-.914 1.656-1.25-3.16.656-10.375.758-10.375.187 0-.523 2.312-1.062 2.312-1.062s-.328-.016-.812 0Zm-.438 2.875c-.793 0-1.969.617-1.969 1.218 0 1.211 5.97 2.141 10.376.375l-1.532-.937c-2.988.977-8.508.652-6.875-.656Zm.75 2.718c-1.082 0-1.781.684-1.781 1.188 0 1.55 6.465 1.703 9.031.125l-1.625-1.063c-1.914.825-6.73.946-5.625-.25Zm-3.625 1.407C7.141 23.059 6 23.859 6 24.53c0 3.574 18.094 3.403 18.094-.25 0-.605-.715-.894-.969-1.031 1.477 3.492-14.781 3.219-14.781 1.156 0-.468 1.203-.937 2.312-.718l-.937-.532a5.962 5.962 0 0 0-.813-.062ZM26 25.5c-2.75 2.66-9.71 3.613-16.719 1.969 7.008 2.93 16.684 1.3 16.719-1.969Z" />
    </svg>
)
export default JavaSVG
