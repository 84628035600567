import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { ContactSection } from "./styles";
import ContactMe from "../../components/ContactMe";
import { SectionVisibilityContext } from "../../context/SectionVisibilityContext";

const SECTION_ID = 'contact';

export const Contact: React.FC = () => {
    const { setVisibleSection } = useContext(SectionVisibilityContext)
    const { ref } = useInView({
        onChange: (inView) => {
            if (inView) setVisibleSection("contact")
            else setVisibleSection('projects')
        },
        threshold: 0.1
    })

    return <ContactSection id={SECTION_ID} ref={ref}>
        <h2>Contact Me</h2>
        <span className="body">Connect with me and see how I can create an impact for your company and the world.</span>
        <ContactMe />
    </ContactSection>
} 