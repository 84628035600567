import React, { useState, useRef, useEffect, useContext } from 'react'
import { HeroContainer, TempNavBar as TNB, TitleContainer, NavBarContainer, NavBarItem } from './styles'
import ContactMe from '../../components/ContactMe'
import { SectionVisibilityContext } from '../../context/SectionVisibilityContext'
import { useInView } from 'react-intersection-observer'

type Props = {}
const SECTION_ID = 'hero';

const TempNavBar = () => {
  return <TNB>
    <ContactMe />
  </TNB>
}

const NavBar = () => {

  const [isSticky, setIsSticky] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null)
  const [navBarPosition, setNavBarPosition] = useState<number>(0)
  const { visibleSection } = useContext(SectionVisibilityContext);

  const getNavBarPosition = () => {
    if (navRef.current && navRef.current) {
      const position: number = navRef.current?.offsetTop;
      setNavBarPosition(position)
    }
  }

  useEffect(() => {
    getNavBarPosition();
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      // Check if the navbar's original top position is less than the scroll position
      setIsSticky(window.scrollY > navBarPosition);
    }
    window.addEventListener('resize', getNavBarPosition);
    window.addEventListener('scroll', handleScroll);

    handleScroll()

    return () => {
      window.removeEventListener('resize', getNavBarPosition);
      window.removeEventListener('scroll', handleScroll);
    }
  }, [navBarPosition])

  console.log(visibleSection);


  return <NavBarContainer ref={navRef} className={isSticky ? 'sticky' : ''}>
    <div>
      <div>
        <NavBarItem to={SECTION_ID} smooth duration={500}>
          RAV
        </NavBarItem>
      </div>
      <div>
        <NavBarItem to='about' data-testid={'about'} smooth duration={500} className={`${!isSticky ? 'hopping' : ''}`}>
          ABOUT
        </NavBarItem>
        <NavBarItem to='projects' data-testid={'projects'} smooth duration={500}
        // className={`${visibleSection == 'projects' && 'visible'}`}
        >
          PROJECTS
        </NavBarItem>
        {/* <NavBarItem>
          CV
        </NavBarItem> */}
        <NavBarItem to='contact' data-testid={'contact'} smooth duration={500}
        // className={`${visibleSection == 'contact' && 'visible'}`}
        >
          CONTACT
        </NavBarItem>
      </div>
    </div>
  </NavBarContainer>
}

const Title = () => {
  return <TitleContainer>
    <h1>RAVINDER SHOKAR</h1>
    <h3>Full Stack Developer</h3>
    <NavBar />
  </TitleContainer>
}



const Hero = () => {
  const { setVisibleSection } = useContext(SectionVisibilityContext);
  const { ref } = useInView({
    threshold: 1,
    onChange: (inView) => {
      if (inView) setVisibleSection('HERO');
    }
  });



  return (
    <HeroContainer ref={ref} id={SECTION_ID}>
      <TempNavBar />
      <Title />
    </HeroContainer>
  )
}

export default Hero