import styled from "styled-components";

export const SectionWrapper = styled.div<{ image?: string }>`
    background-color: ${props => props.theme.color.background};
    background-size: cover;
    ${props => props.image && `background-image: url(${props.image})`};  
`

export const SectionContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto; 
    padding: 0 5% 0 5%;
    padding-top: 100px;
    padding-bottom: 100px;
`

